import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { marked } from "marked"
import Layout from "../../components/Layout/Layout"
import Template from "../../components/Template/Template"
import YoutubeVideo from "../../components/YoutubeVideo/YoutubeVideo"
import t from "../../translations"
import useIsMobile from "../../hooks/useIsMobile"
import styles from "./Client.module.scss"
import { renderContentNode } from "../../utilities/renderContentNode"
import { renderTags } from "../../utilities/renderTags"
import { Container } from "react-bootstrap"
import Link from "../../components/Link/Link"
import SEO from "../../components/seo"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
import { doesTagsContainNoIndex, getHTMLLang } from "../../utilities/utilities"
import MarkdownTitle from "../../components/MarkdownTitle"
// import getValueOrDefault from "../../analytics/getValueOrDefault"

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulClient(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      slug
      node_locale
      name
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      youtubeVideoId
      videoName
      activityArea
      companySize
      location
      reason
      pagePerimeter
      pagePublication
      pageFeature
      segmentPublishedAt: publishedDate(formatString: "yyyyMMDD")
      segmentUpdatedAt: updatedAt(formatString: "yyyyMMDD")
      body {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            description
            gatsbyImageData(layout: CONSTRAINED, width: 666)
          }
          ... on ContentfulTestimonial {
            contentful_id
            author
            detail
            testimonial {
              testimonial
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      ctaContent {
        ctaContent
      }
      ctaLink {
        ...linkFields
      }
      template {
        ...templateFields
      }
      extraTags {
        ...extraTagFields
      }
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }
  }
`

const Client = props => {
  useScrollTracking()
  usePageTracking()
  const { localeLinks, locale, defaultLocale } = props.pageContext
  const isMobile = useIsMobile()

  const option = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (node.data.target) {
          return (
            <GatsbyImage
              image={node.data.target.gatsbyImageData}
              className={styles.media}
              alt={node.data.target.description}
            />
          )
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return renderContentNode(node, locale, defaultLocale)
      }
    }
  }

  const { ctaContent, ctaLink } = props.data.contentfulClient
  const containsCTAContent = ctaContent && ctaLink

  function renderCTASection() {
    return (
      <div className={styles.cta}>
        <div
          className={styles.ctaContent}
          dangerouslySetInnerHTML={{ __html: marked(ctaContent.ctaContent) }}
        ></div>
        <Link
          className={styles.action}
          link={ctaLink}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      </div>
    )
  }

  function renderBody(body, options) {
    if (!body || !options) {
      return null
    }
    return renderRichText(body, options)
  }

  const htmlLang = getHTMLLang(locale)
  const title = _.get(props, "data.contentfulClient.metaTitle", "")
  const metaDescription = _.get(
    props,
    "data.contentfulClient.metaDescription.metaDescription",
    ""
  )
  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`

  const {
    data: { contentfulClient }
  } = props

  const pageData = getPageData(
    locale,
    {
      pagePerimeter: "client",
      pagePublication: _.get(
        contentfulClient,
        "segmentPublishedAt",
        contentfulClient.segmentUpdatedAt
      ),
      pageFeature: _.get(contentfulClient, "pageFeature", "")
    },
    contentfulClient.slug,
    props.path
  )
  const doesPageContainsNoIndexTag =
    doesTagsContainNoIndex(props.data.contentfulClient.extraTags) ||
    (props.data.contentfulClient.template &&
      props.data.contentfulClient.template.headTags &&
      doesTagsContainNoIndex(props.data.contentfulClient.template.headTags))
  return (
    <Layout locale={props.pageContext.locale} defaultLocale={defaultLocale}>
      <SEO
        description={metaDescription}
        lang={htmlLang}
        title={title}
        url={pageUrl}
        includeSelfCanonical={!doesPageContainsNoIndexTag}
      />
      <Helmet>
        <script type="text/javascript">{`${pageData}`}</script>
        {renderTags(
          locale,
          props.pageContext.defaultLocal,
          props.data.contentfulClient.extraTags
        )}
        {props.data.contentfulClient.template &&
          props.data.contentfulClient.template.headTags &&
          renderTags(
            locale,
            props.pageContext.defaultLocale,
            props.data.contentfulClient.template.headTags
          )}
      </Helmet>
      <Template
        {...props.data.contentfulClient.template}
        isMenuFixed={true}
        localeLinks={localeLinks}
        locale={props.pageContext.locale}
        defaultLocale={defaultLocale}
      >
        <div
          className={styles.client}
          style={{ paddingTop: isMobile ? "6rem" : "8rem" }}
        >
          <Container>
            <div className={styles.head}>
              <div className={styles.title}>
                <h1>
                  <MarkdownTitle text={props.data.contentfulClient.title} />
                </h1>
              </div>
              <div className={styles.media}>
                <YoutubeVideo
                  videoName={props.data.contentfulClient.videoName}
                  videoId={props.data.contentfulClient.youtubeVideoId}
                />
              </div>
            </div>

            <div className={styles.body}>
              <div className={styles.aside}>
                <div className={styles.info}>
                  {props.data.contentfulClient.activityArea && (
                    <div>
                      <b>
                        {t.translate(
                          t.KEYS.CLIENT_ACTIVITY_AREA,
                          props.pageContext.locale
                        )}
                        :
                      </b>{" "}
                      {props.data.contentfulClient.activityArea.join(", ")}
                    </div>
                  )}
                  {props.data.contentfulClient.companySize && (
                    <div>
                      <b>
                        {t.translate(
                          t.KEYS.CLIENT_SIZE,
                          props.pageContext.locale
                        )}
                        :
                      </b>{" "}
                      {props.data.contentfulClient.companySize.join(", ")}
                    </div>
                  )}
                  {props.data.contentfulClient.location && (
                    <div>
                      <b>
                        {t.translate(
                          t.KEYS.CLIENT_LOCATION,
                          props.pageContext.locale
                        )}
                        :
                      </b>{" "}
                      {props.data.contentfulClient.location}
                    </div>
                  )}
                  {props.data.contentfulClient.reason && (
                    <div>
                      <b>
                        {t.translate(
                          t.KEYS.CLIENT_CASH_ISSUE,
                          props.pageContext.locale
                        )}
                        :
                      </b>{" "}
                      {props.data.contentfulClient.reason}
                    </div>
                  )}
                </div>
                {containsCTAContent && renderCTASection()}
              </div>
              <div className={styles.content}>
                {renderBody(props.data.contentfulClient.body, option)}
              </div>
            </div>
          </Container>
        </div>
      </Template>
    </Layout>
  )
}

Client.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default Client
